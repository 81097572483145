<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import { AlertDataSend } from "../components/alert";

const url = process.env.VUE_APP_API_URL;

export default {
  name: "AlterarSenha",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      senhaAtual: "",
      novaSenha: "",
      confirmarSenha: "",
      senhaAtualSalva: "",
    };
  },
  props: {
    idEmpresa: String,
    nomePreenchido: Boolean,
    usuarioPreenchido: Boolean,
    empresasConectadasDados: Array,
  },
  validations() {
    return {
      senhaAtual: { required },
      novaSenha: { required, minLength: minLength(8) },
      confirmarSenha: { required, sameAs: sameAs(this.novaSenha) },
    };
  },
  async created() {
    if (this.idEmpresa) {
      try {
        const response = await this.$axios.get(url + "/empresa", {
          params: { id: this.idEmpresa },
        });
        this.senhaAtualSalva = response.data[0].password;
      } catch (error) {
        AlertDataSend("Erro ao recuperar dados da empresa.");
      }
    }
    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscKey);
  },
  methods: {
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.$emit("fecharCadastroEmpresa");
      }
    },
    async confirmar_novaSenha() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        AlertDataSend("Por favor, preencha todos os campos corretamente.");
        return;
      }

      const dados = {
        idCompany: this.idEmpresa,
        passwordCurrent: this.senhaAtual,
        newPassword: this.novaSenha,
        confirmationNewPassword: this.confirmarSenha,
      };

      try {
        await this.$axios.patch(url + "/empresa/password", dados);
        location.reload();
      } catch (error) {
        console.log(error);

        AlertDataSend("Não foi possível alterar a senha.");
      }
    },
  },
};
</script>
<template>
  <main class="conteudo">
    <section>
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">
          <span> Criar nova senha</span>
          <span @click="$emit('fecharCadastroEmpresa')" class="btn_sair">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div class="formConteiner">
        <form class="item" id="elemento_form">
          <div class="caixa_input">
            <label for="senhaAtual">Senha atual</label>
            <div class="input_icon">
              <input
                v-model="senhaAtual"
                type="password"
                id="senhaAtual"
                placeholder="Senha atual"
                autocomplete="off"
                class="input_padraoo"
              />
              <span v-if="v$.senhaAtual.$error" class="error-message">Senha atual é obrigatória.</span>
            </div>
          </div>
          <div class="caixa_input">
            <label for="novaSenha">Nova senha</label>
            <div class="input_icon">
              <input
                v-model="novaSenha"
                type="password"
                id="novaSenha"
                placeholder="Nova senha"
                autocomplete="off"
                class="input_padraoo"
              />
              <span v-if="v$.novaSenha.$error" class="error-message"
                >Nova senha é obrigatória.</span
              >
              <span v-if="v$.novaSenha.$error" class="error-message"
                >A nova senha deve ter pelo menos 8 caracteres.</span
              >
            </div>
          </div>
          <div class="caixa_input">
            <label for="confirmarSenha">Confirmar senha</label>
            <div class="input_icon">
              <input
                v-model="confirmarSenha"
                type="password"
                id="confirmarSenha"
                placeholder="Confirmar senha"
                autocomplete="off"
                class="input_padraoo"
              />
              <span
                v-if="v$.confirmarSenha.$error"
                class="error-message"
                >Confirmação de senha é obrigatória.</span
              >
              <span v-if="v$.confirmarSenha.$error" class="error-message"
                >As senhas não coincidem.</span
              >
            </div>
          </div>
          <div>
            <button
              type="button"
              class="button-alterar"
              @click="confirmar_novaSenha"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>
<style scoped>
.error-message{
  color: red;
}
.input_padraoo {
  width: 100%;
  background-color: white;
  height: 3vw;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 1rem;
}
.input_NVsenha {
  width: 100%;
  background-color: #f0f0f0;
  color: #b3b3b3;
  height: 3vw;
  border: none;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 1rem;
}
.button-alterar {
  width: 23vw;
  border: 1px solid #2bb542;
  color: white;
  background-color: #2bb542;
  cursor: pointer;
  font-size: 1rem;
  padding: 15px 5px;
  border-radius: 3px;
}
.traco {
  display: flex;
  width: 100%;
  border: 1px solid #b3b3b3;
}

.usuario-style {
  width: 100%;
}
.senha-usuario-style {
  float: left;
  display: flex;
  justify-content: space-between;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  width: 100%;
}

.btn_sair {
  cursor: pointer;
  color: red;
}

.formConteiner {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}

.caixaTitulo {
  width: 100%;
}

section {
  border-radius: 10px;
  display: flex;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  justify-content: space-around;
  flex-direction: column;
}

.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Cor de fundo semitransparente */
  font-family: Arial, Helvetica, sans-serif;
}

.conteudo .item {
  height: 100%;
}

.caixa_input {
  width: 100%;
}

.espaco_imagem {
  height: 60%;
  aspect-ratio: 1/1;
}

#elemento_form,
#elemento_imagem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#senha {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.verSenha {
  display: flex;
  align-items: center;
  width: 87%;
}

.verSenha button {
  width: 15%;
  background: transparent;
  color: black;
  border: 1px solid #b3b3b3;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}

.state {
  cursor: pointer;
}

#input-img {
  display: none;
}

.input-imagen {
  text-align: center;
  border: 1px solid #b3b3b3;
  padding: 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

#textoobservacao {
  font-size: 13px;
  color: #b3b3b3;
}

#geraSenha {
  background-color: white;
  height: 30px;
  border-radius: 5px;
  font-weight: 100;
  cursor: pointer;
}

.botao_padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 30px;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 2px;
}

.caixa_botao {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
