<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AlertDataSend } from "../alert";

const url = process.env.VUE_APP_API_URL;
const urlSocket = process.env.VUE_APP_API_URL_SOCKET;
const urlComunicationQlik = process.env.VUE_APP_API_URL_QLIK;

export default {
  name: "formConfig",
  emits: ["mudar_estado", "salvarDadosConexao"],

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      sessao: "",
      tenantPrefix: "https://",
      cloudDomain: ".us.qlikcloud.com",
      tenant: "",
      appkey: "",
      model: "",
      tenantInput: "",
    };
  },

  props: {
    enviarDados: String,
    status_conexao: String,
    credentials: Object,
  },

  watch: {
    enviarDados(newVal) {
      if (newVal === "conectar") {
        const conectar = true;
        this.confirma_envio(conectar);
      }
      if (newVal === "enviar") {
        this.confirma_envio_dados();
      }
    },
    credentials(newvalue) {
      this.updateCredentials(newvalue);
    },
  },

  validations() {
    return {
      tenantInput: { required },
      appkey: { required },
    };
  },
  async created() {
    const localStorageObject = await JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;
  },
  methods: {
    message(text, tipo) {
      AlertDataSend(text, tipo);
    },
    async confirma_envio() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message("Preencha os campos corretamente");
        return;
      }

      // const verificationResult = await this.verification();
      // if (!verificationResult) {
      //   this.message("Erro na verificação. Por favor, corrija os dados.");
      //   return;
      // }

      this.$emit("salvarDadosConexao", {
        sessao: this.sessao,
        appkey: this.appkey,
        tenant: this.tenant,
      });
      this.$emit("mudar_estado");
    },
    // async verification() {
    //   const jsonData = { idCompany: this.idEmpresa, tenant: this.tenant, appkey: this.appkey };
    //   try {
    //     const panel = await this.$axios.get(
    //       urlComunicationQlik + "/validate",
    //       {
    //         params: jsonData,
    //       }
    //     );
    //     return panel;
    //   } catch (error) {
    //     AlertDataSend("Tenant ou Appkey incorretos.");
    //     return null; 
    //   }
    // },
    atualizarTenant(event) {
      const value = event.target.value;
      if (value === "Selecione") {
        this.tenantInput = "";
      } else {
        this.tenantInput = this.tenantPrefix + value + this.cloudDomain;
      }
    },
    handleTenant() {
      this.tenant = this.tenantPrefix + this.tenantInput + this.cloudDomain;
    },
    getData() {
      return {
        sessao: this.sessao,
        appkey: this.appkey,
        tenant: this.tenant,
      };
    },
    updateCredentials(newvalue) {
      this.sessao = newvalue.sessao;
      this.appkey = newvalue.appkey;
      this.tenant = newvalue.tenant;
      this.tenantInput = this.disassembleTenant(newvalue.tenant);
    },
    disassembleTenant(value) {
      if (value) {
        const formatedFirst = value.replace("https://", "");
        const formatedLast = formatedFirst.replace(".us.qlikcloud.com", "");
        return formatedLast;
      }
    },
    async confirma_envio_dados() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message("Preencha os campos corretamente");
        return;
      }

      // const verificationResult = await this.verification();
      // if (!verificationResult) {
      //   this.message("Erro na verificação. Por favor, corrija os dados.");
      //   return;
      // }

      const tenantCompleto =
        this.tenantPrefix + this.v$.tenant.$model + this.cloudDomain;
      this.$emit("salvarDadosConexaoParaDesconectar", {
        sessao: this.sessao,
        appkey: this.appkey,
        tenant: tenantCompleto,
      });
      this.$emit("mudar_estado");
    },
  },
};
</script>

<template>
  <main class="style-formQlik">
    <div class="style-titulo">Conectar ao Qlik</div>
    <div class="style-all">
      <div class="caixa_input">
        <label>APPKEY</label>
        <div class="caixa_input">
          <input
            v-model="v$.appkey.$model"
            type="text"
            class="input_padrao"
            placeholder="Appkey"
            autocomplete="off"
          />
          <!-- <div class="DicaTexto">Preencha com a sua Appkey</div> -->
          <p class="input_error" v-if="v$.appkey.$error">Campo obrigatório</p>
        </div>
      </div>
      <div>
        <div class="style-space">
          <label class="ttt">TENANT</label>
          <label for="">SERVIDOR</label>
        </div>
        <div class="input-tnt">
          <input
            v-model="v$.tenantInput.$model"
            type="text"
            class="input_padrao"
            placeholder="https://exemplo.us.qlikcloud.com"
            autocomplete="off"
            @change="handleTenant"
          />
          <!-- <div class="DicaTexto">Preencha com o seu Tenant</div> -->
          <select class="select-conte" @change="atualizarTenant">
            <option>Selecione</option>
            <option value="us.qlikcloud.com" selected>us.qlikcloud.com</option>
          </select>
        </div>
        <div class="space-input">
          <p class="input_errorr" v-if="v$.tenantInput.$error">
            Campo obrigatório
          </p>
        </div>
      </div>
      <div class="Dica">
        <button class="botao_padrao" @click="confirma_envio">
          Conectar
          <!-- <div class="DicaTexto">Salvar Dados</div> -->
        </button>
      </div>
    </div>
  </main>
</template>

<style scoped>
.style-formQlik {
  display: flex;
  height: 20vh;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  border-radius: 5px;
}
.style-all {
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 60%;
  justify-items: flex-start;
  margin-left: -25vw;
  margin-top: 4vw;
}
.style-space {
  display: flex;
  gap: 11.8vw;
}
.style-titulo {
  display: flex;
  font-size: 1.5rem;
  font: bold;
  padding: 15px;
  margin-left: 1vw;
  width: 30%;
}
.error {
  border: 1px solid rgb(255, 108, 108);
}

span {
  color: rgb(255, 108, 108);
  font-size: 0.8rem;
}

.botao_padrao {
  background-color: #2bb542;
  color: white;
  width: 8vw;
  margin-top: 1vw;
}

.botao_padrao:hover {
  background-color: #2bb542cc;
}
input {
  color: black;
}
.space-input {
  display: flex;
  width: 100%;
  height: 2em;
  font-size: 1.2rem;
}
.input_errorr {
  color: rgba(255, 0, 0, 0.551);
  font-size: 0.8rem;
}
.input_padrao {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  width: 26vw;
}

.input-tnt {
  height: 100%;
  display: flex;
  gap: 1rem;
}

.select-conte,
.input-tnt {
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
  flex-direction: row;
}

.select-conte,
.input-tnt {
  display: flex;
  width: 100%;
}

.ttt {
  height: 20px;
}

.Dica {
  position: relative;
  display: inline-block;
}

.Dica:hover .DicaTexto {
  visibility: visible;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.input-tnt {
  position: relative;
}

.input-tnt:hover .DicaTexto {
  visibility: visible;
}

.caixa_input {
  position: relative;
  height: 30px;
}

.caixa_input:hover .DicaTexto {
  visibility: visible;
}
</style>
