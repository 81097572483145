<script>
//import this.$axios.get from "this.$axios.get";
import { AlertDataSend } from "../alert";
const url = process.env.VUE_APP_API_URL;
export default {
  props: {
    mensagemPadrão: Object,
    idEmpresa: null,
    mensagemAlterada: Object,
  },
  data() {
    return {
      exibirChatbot: true,
      mensagemExibida: this.msg,
      mensagemPersonalizada: "",
      checkPattern: true,
      checkboxPersonalizadoMarcado: false,
      isModalOpen:false
    };
  },
  methods: {
    fechar() {
      this.$emit("fechar",);
    },
    async salvar() {
      try {
        if (!this.mensagemPersonalizada) {
            AlertDataSend('Por favor, preencha a mensagem personalizada.');
          return;
        }
        if (!this.mensagemAlterada.nothingInTheDatabase) {
          this.atualizarmsg();
          this.$emit("mensagemPersonalizadaSalva", {
            msg: this.mensagemPersonalizada,
            id: this.mensagemPadrão.id
          });
        } else if (this.mensagemAlterada.nothingInTheDatabase) {
          this.$emit("mensagemPersonalizadaSalva", {
            msg: this.mensagemPersonalizada,
            id: this.mensagemPadrão.id
          });
          await this.$axios.post(url + `/message-pattern`, {
            idEmpresa: this.idEmpresa,
            message: this.mensagemPersonalizada,
            messageCode: this.mensagemPadrão.id,
          });
          this.fechar();
        }
      } catch (error) {
        this.fechar();
      }
    },
    async atualizarmsg() {
      this.$axios
        .put(url + `/message-pattern`, {
          idEmpresa: this.idEmpresa,
          message: this.mensagemPersonalizada,
          messageCode: this.mensagemPadrão.id,
        })
        .then(() => {
          this.fechar();
        })
        .catch((response) => console.log("Erro ao atualizar", response));
    },
    inputPersonalizadoSelecionado() {
      this.checkPattern = false
    },
    toggleCheckboxPersonalizado() {
      this.checkboxPersonalizadoMarcado = !this.checkboxPersonalizadoMarcado;
    },
    updateInputNewMessage(value) {
      this.mensagemPersonalizada = value;
    },
    voltarMsgPadrao() {
      this.$emit("voltarMsgPadrao", { id: this.mensagemPadrão.id })
    },
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.fechar();
      }
    },
    async voltarPadrao() {
      if (this.checkboxPersonalizadoMarcado) {
        this.checkboxPersonalizadoMarcado = false
        await this.$axios
          .delete(url + `/message-pattern`, {
            data: {
              idEmpresa: this.idEmpresa,
              message: this.mensagemPersonalizada,
              messageCode: this.mensagemPadrão.id,
            }
          })
          .then(() => {
            this.voltarMsgPadrao();
            this.fechar();
          })
          .catch((response) => console.error('Erro ao excluir mensagem:', response));

      }
    },
    handleClickOutside() {
      if (!this.isModalOpen) {
        this.fechar();
      }
      this.isModalOpen =false
    },
    updateInputNewMessage(value) {
      if (value.length <= 200) {
        this.mensagemPersonalizada = value;
      }
    },
  },
  created() {
    if (!this.mensagemAlterada.nothingInTheDatabase) {
      this.mensagemPersonalizada = this.mensagemAlterada.msg;
      this.checkPattern = false
      this.checkboxPersonalizadoMarcado = true
    }
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener("keydown", this.handleEscKey);
    this.isModalOpen = true
  },
  beforeDestroy() {
    document.addEventListener('click', this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscKey);
  },
};
</script>

<template>
  <main class="conteudo" v-if="exibirChatbot">
    <div class="popup" ref="modalContent" @click.stop>
      <section class="botoes">
        <span style="font-size: 1.8rem; font-weight: bolder">
          <p>Mensagem de {{ mensagemPadrão.nameMsg }}</p>
        </span>
        <span id="tste" @click="fechar" style="cursor: pointer; color: red; font-size: 1.5rem">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </section>
      <span> </span>
      <div class="style-spaceon">
        <div class="space-p">
          <p class="style-padrao">Padrão</p>
        </div>
        <div class="area-pattern" style="color: green">
          <input type="checkbox" @change="voltarPadrao()" v-model="checkPattern" />
          <textarea type="text" class="input-padrao" style="resize: none" :value="mensagemPadrão.msg" disabled />
        </div>
      </div>
      <div class="style-spacetwo">
        <div class="space-p">
          <p class="style-personalizado">Personalizado</p>
        </div>
        <div class="area-custom">
          <input type="checkbox" v-model="checkboxPersonalizadoMarcado" @change="inputPersonalizadoSelecionado" />
          <textarea type="text" class="input-personalizado" style="resize: none" v-if="!mensagemAlterada.nothingInTheDatabase"
            placeholder="Clique aqui para criar nova mensagem" :disabled="!checkboxPersonalizadoMarcado"
            @input="updateInputNewMessage($event.target.value)" v-model="mensagemPersonalizada" maxlength="200" />
          <textarea type="text" class="input-personalizado" v-else placeholder="Clique aqui para criar nova mensagem"
            :disabled="!checkboxPersonalizadoMarcado" @input="updateInputNewMessage($event.target.value)" maxlength="200" />
          </div>
          <div class="all-caracteres">
            <p class="style-caracteres">Caracteres: {{ mensagemPersonalizada.length }}</p><p class="style-contador">/ 200</p> 
          </div>
      </div>
      <div></div>
      <div class="style-spacetree">
        <div class="style-button">
          <button class="button-style" @click="salvar()">Salvar</button>
        </div>
      </div>
    </div>
  </main>
</template>


<style scoped>
.popup {
  width: 48vw;
  height: 75vh;
  background: white;
  font-size: 1rem;
  border-radius: 10px;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  top: 6vw;
  left: 32vw;
  z-index: 1;
}
.all-caracteres{
  display: flex;
  padding: 5px 15px;

}
.style-caracteres{
  display: flex;
  font-size: 0.9rem;
  padding: 5px 
}
.style-contador{
  display: flex;
  color:  rgba(185, 184, 184, 0.84);
  font-size: 0.9rem;
  padding: 5px 
  
}
.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.104);
  /* Cor de fundo semitransparente */
  font-family: Arial, Helvetica, sans-serif;
}

.style-padrao {
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: bolder;
}

.style-personalizado {
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: bolder;
}

.style-conment {
  width: 80%;
}

.space-p {
  width: 100%;
  height: 1.5em;
}

.area-custom{
  display: flex;
}

.area-pattern{
  display: flex;
}

.style-spacetree {
  width: 100%;
  height: 4em;
  align-items: center;
  font-size: 1.2rem;
}

.style-spacetwo {
  width: 100%;
  height: 8em;
  align-items: center;
  font-size: 1.2rem;
}

.style-spaceon {
  width: 100%;
  height: 8em;
  align-items: center;
  font-size: 1.2rem;
}

.input-padrao {
  height: 6vw;
  width: 40.3vw;
  border-radius: 5px;
  border: 2px solid rgba(185, 184, 184, 0.84);
  margin-left: 5px;
  resize: none;
  box-sizing: border-box;
  padding: 1.5rem 1.5rem;
}

.input-personalizado {
 display: flex;
 align-items: center;
  height: 7.5vw;
  width: 40.3vw;
  border-radius: 5px;
  border: 2px solid rgba(185, 184, 184, 0.84);
  margin-left: 5px;
  resize: none;
  box-sizing: border-box;
  padding: 2.8rem 1.5rem ;
}

.style-button {
  display: flex;
  justify-content: space-between;
}

.button-style {
  height: 2.5rem;
  width: 10rem;
  border: none;
  border-radius: 3px;
  color: white;
  background-color: #2bb542;
  cursor: pointer;
  margin-left: 31vw;
}

.botoes .botao_popup {
  border: none;
  width: 8vw;
  color: white;
}

.botoes {
  display: flex;
  width: 40vw;
  justify-content: space-between;
  width: 100%;
}
</style>