<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AlertDataSend } from "../alert";
//import axios from "axios";

const url = process.env.VUE_APP_API_URL;
const urlSocket = process.env.VUE_APP_API_URL_SOCKET;

export default {
  name: "formConfig",
  emits: ["mudar_estado", "salvarDadosConexao"],

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      sessao: "",
      model: "",
      idEmpresa: null,
      urlPadrao: "https://datasend.com.br/hook",
      idWhatsApp: "",
      tokenMeta: "",
    };
  },
  props: {
    enviarDados: String,
    status_conexao: String,
    credentials: Object,
  },
  validations() {
    return {
      urlPadrao: { required },
      idWhatsApp: { required },
      tokenMeta: { required },
    };
  },
  async created() {
    const localStorageObject = await JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;
    const response = await this.$axios.get(`${url}/connection-wa/${this.idEmpresa}`);
    //console.log(response.data);
    
      const { id_wa, token, urlPadrao } = response.data;

      this.idWhatsApp = id_wa;
      this.tokenMeta = token;
      if (urlPadrao) {
        this.urlPadrao = urlPadrao;
      }
  },
  methods: {
    message(text, tipo) {
      AlertDataSend(text, tipo);
    },
    copiarUrl() {
      navigator.clipboard
        .writeText(this.urlPadrao)
        .then(() => {
          AlertDataSend("URL copiada com sucesso!", "alert");
        })
        .catch((err) => {
          console.error("Erro ao copiar a URL: ", err);
        });
    },
    async confirma_envio() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.message(
          "Preencha todos os campos obrigatórios corretamente.",
          "error"
        );
        return;
      }
      const payload = {
        idCompany: this.idEmpresa,
        idWa: this.idWhatsApp,
        token: this.tokenMeta,
      };
      console.log(payload);
      try {
        const response = await this.$axios.post(url + "/connection-wa", payload);
        AlertDataSend("Dados salvos com sucesso!", "alert");
      } catch (error) {
        AlertDataSend("Erro ao conectar com a Meta.", "error");
        console.error("Erro na requisição:", error);
      }
    },
  },
};
</script>

<template>
  <main class="item" id="elemento_form">
    <label class="titulo_form">Conectar ao WhatsApp Meta</label>
    <div class="caixa_input">
      <label>Copia URL</label>
      <div class="caixa_input" style="display: flex; align-items: center">
        <input
          style="background-color: rgba(185, 184, 184, 0.247)"
          v-model="urlPadrao"
          type="text"
          class="input_padrao"
          placeholder="Insira ou copie a URL"
          autocomplete="off"
          disabled
        />
        <button
          @click="copiarUrl"
          class="botao_copia"
          style="margin-left: 10px"
        >
          Copiar
        </button>
      </div>
    </div>
    <div>
      <label class="ttt">ID WhatsApp Business</label>
      <div class="input-tnt">
        <input
          v-model="idWhatsApp"
          type="text"
          class="input_padrao"
          placeholder="ID do WhatsApp Business"
          autocomplete="off"
          @change="handleTenant"
        />
      </div>
      <p class="input_errorr" v-if="v$.idWhatsApp.$error">Campo obrigatório</p>
      <div class="space-input">
        <!-- <p class="input_errorr" v-if="v$.tenantInput.$error">
          Campo obrigatório
        </p> -->
      </div>
    </div>
    <div>
      <label class="ttt">Token Meta</label>
      <div class="input-tnt">
        <input
          v-model="tokenMeta"
          type="text"
          class="input_padrao"
          placeholder="Token Meta"
          autocomplete="off"
          @change="handleTenant"
        />
      </div>
      <p class="input_errorr" v-if="v$.tokenMeta.$error">Campo obrigatório</p>
      <div class="space-input">
        <!-- <p class="input_errorr" v-if="v$">
          Campo obrigatório
        </p> -->
      </div>
    </div>
    <div class="Dica">
      <button class="botao_padrao" @click="confirma_envio()">
        Conectar
        <!-- <div class="DicaTexto">Salvar Dados</div> -->
      </button>
    </div>
  </main>
</template>

<style scoped>
.error {
  border: 1px solid rgb(255, 108, 108);
}

span {
  color: rgb(255, 108, 108);
  font-size: 0.8rem;
}
.botao_copia {
  background-color: white;
  border: 1px solid rgb(217, 217, 217);
  color: black;
  height: 5vh;
  width: 40%;
  cursor: pointer;
  font-size: 1.1rem;
}
.item {
  display: flex;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  height: 20vh;
  border-radius: 5px;
  padding: 20px;
  gap: 1rem;
}
.botao_padrao {
  background-color: #2bb542;
  color: white;
}

.botao_padrao:hover {
  background-color: #2bb542cc;
}

input {
  color: black;
}
.space-input {
  display: flex;
  width: 100%;
  font-size: 1.2rem;
}
.input_errorr {
  color: rgba(255, 0, 0, 0.551);
  font-size: 0.8rem;
}
.input_padrao {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.input-tnt {
  height: 100%;
  display: flex;
  gap: 1rem;
}

.select-conte,
.input-tnt {
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
  flex-direction: row;
}
#elemento_form {
  display: flex;
  height: 55vh;
  width: 100%;
}
.select-conte,
.input-tnt {
  display: flex;
  width: 100%;
}

.ttt {
  height: 20px;
}

.Dica {
  position: relative;
  display: inline-block;
}

.Dica:hover .DicaTexto {
  visibility: visible;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.input-tnt {
  position: relative;
}

.input-tnt:hover .DicaTexto {
  visibility: visible;
}

.caixa_input {
  position: relative;
}

.caixa_input:hover .DicaTexto {
  visibility: visible;
}
</style>
