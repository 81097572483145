<template>
  <div class="all-stylee">
    <div class="wrapper">
      <div class="select-btn" @click="openSelected">
        <span>{{ selected }}</span>
        <i
          class="fas fa-angle-down"
          id="icon-style"
          :class="{ rotated: showSelected }"
        ></i>
      </div>
      <div class="content" v-if="showSelected">
        <ul class="options">
          <li
            @click="updateSearch(option)"
            v-for="(option, index) in typeUser"
            :key="index"
            class="option-item"
          >
            <div class="style-p">
              <span
                style="font-size: 0.89rem; width: 100%"
                @click="updateSearch(option)"
                >{{ option.nome }}</span
              >
              <span class="remove-option" @click.stop="removeOption(option)">
                <i class="fas fa-times"></i>
              </span>
            </div>
          </li>
          <button class="button-expediente" @click="adcExpediente">
            <i class="fa-solid fa-plus"></i> Adicionar Tipo
          </button>
        </ul>
      </div>
    </div>
    <div v-if="showPopup" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <div class="caixaTitulo_titulo">
          <h3 class="popup-title">Cadastrar tipo de usuário</h3>
          <button class="btn_sair" @click="closePopup"><i class="fa-solid fa-xmark"></i></button>
        </div>
        <div>
          <p>Nome do tipo</p>
          <input
            class="input-Registration"
            type="text"
            v-model="newType"
            placeholder="Digite o tipo de usuário"
          />
        </div>
        <span class="style-button">
          <button class="input_button" @click="saveType">Salvar</button>
        </span>
      </div>
    </div>
  </div>
</template>

  
<script>
const url = process.env.VUE_APP_API_URL;

export default {
  name: "SelectTypeUser",
  props: {
    selected: String,
    spinner: Boolean,
    showLoadButton: Boolean,
    loading: Boolean,
    addExpediente: Boolean,
    expedienteRemove: Boolean,
    idEmpresa: String,
  },
  data() {
    return {
      showSelected: false,
      pesquisa: "",
      typeUser: [],
      showPopup: false,
      newType: "",
      idEmpresa: null,
    };
  },
  async created() {
    const localStorageObject = await JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;

    this.loadTypes();
    //console.log("created() chamado");

    window.addEventListener("click", this.closeOnOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeOnOutsideClick);
  },
  methods: {
    openSelected() {
      this.showSelected = !this.showSelected;
    },
    updateSearch(option) {
      this.$emit("update:selected", option);
      this.$emit("input", option.id);
      this.showSelected = false;
    },
    adcExpediente() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      //this.newType = "";
    },
    async loadTypes() {
      try {
        const response = await this.$axios.get(url + "/type/user", {
          params: { idCompany: this.idEmpresa },
        });

        if (response.status === 200) {
          this.typeUser = response.data;
        } else {
          console.error("Erro ao carregar tipos:", response.data);
        }
      } catch (error) {
        console.error("Erro ao carregar tipos de usuário:", error);
      }
    },
    async saveType() {
      if (this.newType.trim()) {
        try {
          const response = await this.$axios.post(url + "/type/user", {
            name: this.newType,
            idCompany: this.idEmpresa,
          });
          if (response.status === 200) {
            const newOption = { id: response.data.id, nome: this.newType };
            this.typeUser.push(newOption);
            this.$emit("update:selected", newOption);
            this.$emit("input", newOption.id);
            this.newType = "";
            this.closePopup();
          } else {
            console.error("Erro ao salvar tipo:", response.data);
          }
        } catch (error) {
          console.error("Erro ao salvar tipo de usuário:", error);
        }
      }
    },
    async removeOption(option) {
      try {
        const idTypeUser = option.id;
        if (!idTypeUser) {
          console.error("ID do tipo de usuário não encontrado.");
          return;
        }
        const response = await this.$axios.delete(
          url + `/type/user?idTypeUser=${idTypeUser}`
        );
        if (response.status === 200) {
          const index = this.typeUser.indexOf(option);
          if (index !== -1) {
            this.typeUser.splice(index, 1);
          }
        } else {
          console.error("Erro ao remover tipo de usuário:", response.data);
        }
      } catch (error) {
        console.error("Erro ao remover tipo de usuário:", error);
      }
    },
    closeOnOutsideClick(event) {
      const isClickInsideComponent = this.$el.contains(event.target);
      if (!isClickInsideComponent) {
        this.showSelected = false;
      }
    },
  },
};
</script>

  <style scoped>
.remove-option {
  display: flex;
  cursor: pointer;
  color: red;
}
.style-p {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.2rem;
}
.popup-title {
  font-size: 1.5vw;
}
.style-button {
  cursor: pointer;
  display: flex;
  width: 100%;
}
.input-Registration {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #929090;
  height: 6vh;
  padding: 0px 5px;
}
.input_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bolder;
  height: 40px;
  border-radius: 2px;
  padding-left: 5px;
  text-align: center;
  color: white;
  width: 100%;
  background-color: #2bb542;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.btn_sair {
  cursor: pointer;
  background-color: white;
  border: none;
  font-size: 1.2vw;
  color: red;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.9rem;
  justify-content: space-between;
  width: 100%;
}
.wrapper {
  /* width: 15vw; */
  position: relative;
  overflow: hidden;
}
.all-stylee {
  width: 12vw;
  border: none;
  background-color: transparent;
  max-width: 100%;
}
#icon-style {
  font-size: 0.8rem;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10vh;
}

.popup-content {
  background: #fff;
  padding: 20px;
  height: 46%;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  display: grid;
  gap: 5vw;
}
.select-btn,
.options li {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.style-removeExpediente {
  color: red;
  font-size: 1.2rem;
}
.select-btn {
  position: relative;
  width: 17.1vw;
  height: 2.35rem;
  font-size: 17px;
  display: flex;
  padding: 0 10px;
  border: 1px solid #929090;
  border-radius: 5px;
  cursor: pointer;
  color: #929090;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  max-width: 100%;
}
.button-expediente {
  width: 17.9vw;
  height: 5vh;
  color: white;
  background-color: #9290909d;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
}

.select-btn span {
  max-width: 11vw;
  overflow: hidden;
}

.fa-angle-down.rotated {
  transform: rotate(-180deg);
  transition: transform 0.5s linear;
}

.content {
  margin-top: 4px;
  padding: 8px;
  width: 18.4%;
  background: #fff;
  border: 1px solid #929090;
  color: black;
  border-radius: 7px;
  position: fixed;
}

.search input {
  height: 30px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 0 15px 0;
  border: 1px solid #929090;
}

.content .options {
  margin-top: 10px;
  max-height: 20vh;
  overflow-y: auto;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.options::-webkit-scrollbar {
  width: 7px;
}

.options::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 25px;
  border: none;
}

.options li {
  height: 50px;
  padding: 0 35px;
  font-size: 16px;
  border-radius: 5px;
}

.options li:hover {
  background: #eeeded;
}
#button-add:hover .DicaTexto {
  visibility: visible;
}
#button-load {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
  margin-left: 7vw;
}
#button-load:hover .DicaTexto {
  visibility: visible;
}
.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
  