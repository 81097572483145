<script>
export default {
    data(){
        return {
            showModal: true,
        };
    },
    props: {
        text: String,
    },
    methods: {
        confirm(confirmation){
            if(confirmation){
                this.$emit('confirmed');
                this.showModal = false;
            } else {
                this.$emit('canceled');
                this.showModal = false;
            }
        },
    }
}
</script>

<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <p>{{text}} </p>
    </div>
    <div class="modal-button">
      <button @click="confirm(true)" id="btn-sim">Sim</button>
      <button @click="confirm(false)" id="btn-nao">Não</button>
    </div>
  </div>
</template>

<style scoped>
.modal{
  position: absolute;
  background-color: white;
  width: 20vw;
  height: 19vh;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
  z-index: 10;
  margin-left:31rem;
}
.modal-button{
    display: flex;
    white-space: nowrap;
}
#btn-sim {
    background-color: green;
    color: white;
    border-radius: 22px;
    cursor: pointer;
    border: 1px;
}
#btn-nao{
    background-color: red;
    color: white;
    border-radius: 22px;
    cursor: pointer;
    border: 1px;
}
.p{
    height: 50%;
}
.modal-button button{
    width: 80px;
    height: 33px;
    margin-right: 15px;
    margin-left: 15px;
}
p{
    font-size: 20px;
    line-height: 20px;
}
</style>
