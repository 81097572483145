<script>
import SelectSearch from "@/components/Select-Search/SelectSearch.vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SelectTypeUser from "../SelectOrCreate-Remove/SelectTypeUser.vue";
import { AlertDataSend } from "../alert.js";
import axios from "axios";
const url = process.env.VUE_APP_API_URL;
const urlComunicationQlik = process.env.VUE_APP_API_URL_QLIK;

export default {
  props: {
    idEmpresa: String,
    idGrupo: String,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: { SelectSearch, AlertDataSend, SelectTypeUser },
  data() {
    return {
      showHoursPopUp: true,
      filtrargrupo: [],
      filtro: [],
      filtros: [],
      painelTitle: "Selecione",
      paineis: "",
      painel: "",
      sheets: "",
      sheet: "",
      sheetTitle: "Selecione",
      objetos: "",
      objeto: "",
      objetoTitle: "Selecione",
      removeSelecao: false,
      nomeGrupo: null,
      loading: false,
      typeUserTitle: "Selecione",
      idTypeUser: null,
      idEmpresa: null,
    };
  },
  validations() {
    return {
      nomeGrupo: { required },
    };
  },
  async created() {
    await this.typeUserCadastrado();
    if (!this.idGrupo) {
      this.loading = false;

      const panel = await this.getPanel();
      this.paineis = panel.data;
    } else {
      this.loading = true;

      const panel = await this.getPanel();
      this.paineis = panel.data;

      if (this.idGrupo) {
        const response = await this.$axios.get(`${url}/filter/group`, {
          params: { idFilterGroup: this.idGrupo },
        });
        this.nome = response.data.nameGroup;
        this.filtro = response.data.filters;
        this.typeUserTitle = response.data.typeUser.name;
        this.idTypeUser = response.data.typeUser.id;
      }

      this.loading = false;
    }
  },
  methods: {
    async adcionar() {
      // Gambiarra, pretendo resolver futuramente
      await new Promise((res) => setTimeout(res, 10));
      switch (true) {
        case this.painel === "":
          this.painel = { title: "*****" };
        case this.sheet === "":
          this.sheet = { title: "*****" };
        case this.objeto === "":
          this.objeto = { title: "*****" };
          break;
      }
      if (this.painel === "") {
        AlertDataSend("Por favor, selecione um painel.", "alert");
        return;
      } else {
        const dados = {
          painel: this.painel,
          sheet: this.sheet,
          objeto: this.objeto,
        };
        const verify = this.filtro.some((item) => {
          if (
            item.painel.title === dados.painel.title &&
            item.sheet.title === dados.sheet.title &&
            item.objeto.title === dados.objeto.title
          ) {
            return true;
          }
          return false;
        });
        if (!verify) {
          this.filtro.unshift(dados);
        } else {
          AlertDataSend("Filtro já cadastrado");
        }
      }
    },
    async typeUserCadastrado() {
      const localStorageObject = JSON.parse(localStorage.getItem("User"));
      this.idEmpresa = localStorageObject.Id;

      if (this.idEmpresa) {
        try {
          const response = await this.$axios.get(url + "/type/user", {
            params: { idCompany: this.idEmpresa },
          });

          this.filtroTypeUser = response.data;
        } catch (error) {
          console.error("Erro ao carregar os Tipos de usuário:", error);
        }
      }
    },
    async getPanel() {
      const jsonData = { idCompany: this.idEmpresa };
      try {
        const panel = await this.$axios.get(
          urlComunicationQlik + "/panel/all",
          {
            params: jsonData,
          }
        );

        return panel;
      } catch (error) {
        AlertDataSend("Não foi possivel carregar os painéis.");
        setTimeout(() => {
          AlertDataSend("Preencha seus dados em conexão");
        }, 2800);
      }
    },
    limparPastaEObjeto() {
      if (this.sheets) {
        this.sheets = null;
        this.sheet = "";
        this.sheetTitle = "Selecione";
        this.objetos = "";
        this.objeto = "";
        this.objetoTitle = "Selecione";
        this.campos = null;
        this.conteudos = null;
        this.campoTitle = "Selecione";
        this.conteudoTitle = "Selecione";
        this.campo = null;
        this.conteudo = null;
        this.bookmark = "";
        this.bookmarks = "";
        this.bookmarkTitle = "Selecione";
        this.blockFieldAndContent = false;
        this.blockBookmark = false;
      }
    },
    async atualizarSheet() {
      this.spinner = true;
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };

      const reqSheet = await this.$axios.get(
        urlComunicationQlik + "/sheet/all",
        {
          params: jsonData,
        }
      );
      this.sheets = await reqSheet.data;
    },
    updatePainel(option) {
      this.painel = option;
      this.painelTitle = option.title;
    },
    updatePasta(option) {
      this.sheet = option;
      this.sheetTitle = option.title;
    },
    updateTypeUserTitle(option) {
      this.typeUser = option;
      this.typeUserTitle = option.nome;
      this.idTypeUser = option.id;
      console.log("recebido pelo pai", option);
    },
    updateObjeto(option) {
      this.objeto = option;
      this.objetoTitle = option.title;
    },
    remover_filtro(index) {
      this.filtro.splice(index, 1);
    },
    limparObjeto() {
      if (this.objetos) {
        this.objetos = "";
        this.objeto = "";
        this.objetoTitle = "Selecione";
      }
    },
    async atualizarField() {
      this.spinner = true;
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };

      const reqField = await this.$axios.get(
        urlComunicationQlik + "/field/all",
        {
          params: jsonData,
        }
      );
      this.campos = await reqField.data;
    },
    async atualizarObject() {
      this.spinner = true;
      const objJson = {
        idCompany: this.idEmpresa,
        idPanel: this.painel.id,
        idSheet: this.sheet.id,
      };

      const reqObject = await this.$axios.get(
        urlComunicationQlik + "/object/all",
        {
          params: objJson,
        }
      );
      this.objetos = await reqObject.data;
    },
    async atualizarGrupo(dadosGrupo) {
      try {
        const response = await this.$axios.patch(
          `${url}/filter/group${this.idGrupo}`,
          dadosGrupo
        );
        AlertDataSend("Grupo atualizado com sucesso!", "alert");
        this.$emit("grupo-cadastrado", {
          nome: dadosGrupo.nameGroup,
          ...dadosGrupo,
          id: response.data.id,
        });
        this.$emit("close");
      } catch (error) {
        AlertDataSend("Erro ao atualizar o grupo.");
      }
    },
    async atualizar() {
      const dadosGrupo = {
        idCompany: this.idEmpresa,
        nameGroup: this.nome,
        filters: this.filtro,
        idFilterGroup: this.idGrupo,
        idTypeUser: this.idTypeUser,
      };
      try {
        const response = await this.$axios.patch(
          `${url}/filter/group`,
          dadosGrupo
        );
        AlertDataSend("Grupo atualizado com sucesso!", "alert")
        this.$emit("grupo-cadastrado", {
          nome: dadosGrupo.nameGroup,
          ...dadosGrupo,
          id: response.data.id,
        });
        this.$emit("close");
      } catch (error) {
        console.error("Erro ao atualizar o grupo:", error);
        AlertDataSend("Erro ao atualizar o grupo.");
      }
    },
    async salvar() {
      // if (!this.idTypeUser) {
      //   AlertDataSend("Por favor, selecione o tipo de usuário.", "alert");
      //   return;
      // }
      if(this.filtro.length === 0) {
        AlertDataSend("Por favor, adicione pelo menos um filtro.");
        return;
      }
      this.filtro = this.filtro.map((item) => ({
        ...item,
        campo: { title: null },
        conteudo: { title: null },
        bookmark: { title: "*****" },
        favorito: false,
      }));
      const dadosGrupo = {
        idCompany: this.idEmpresa,
        nameGroup: this.nome,
        filters: this.filtro,
        idTypeUser: this.idTypeUser,
      };
      try {
        if (!this.idGrupo) {
          const response = await this.$axios.post(
            url + "/filter/group",
            dadosGrupo
          );
          AlertDataSend("Grupo salvo com sucesso!", "alert");

          this.$emit("grupo-cadastrado", {
            nome: dadosGrupo.nameGroup,
            ...dadosGrupo,
            id: response.data,
          });
          this.$emit("close");
        } else {
          await this.atualizar();
          //console.log(this.filtro);
        }
      } catch (error) {
        console.error(error);
        AlertDataSend("Erro ao salvar o grupo.");
      }
    },
    fechar() {
      this.$emit("close");
    },
  },
};
</script>
<template>
  <main class="base">
    <section class="conteudo">
      <div class="popup">
        <div class="caixaTitulo_titulo">
          <span>Cadastrar grupo de filtros</span>
          <span @click="fechar" class="btn_sair">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div>
          <div class="space-style">
            <p class="style-padrao">Nome do grupo</p>
            <p style="margin-right: 1%" class="style-padrao">
              Vincular tipo de usuários
            </p>
          </div>
          <span class="name-group-style">
            <input
              :class="[{ error: v$.nomeGrupo.$error }]"
              v-model="nome"
              type="text"
              id="nome"
              class="input_padrao"
              placeholder="Digite o nome"
            />
            <p class="style-obgr" v-if="v$.nomeGrupo.$error">
              Campo obrigatório
            </p>
            <div class="style-type">
              <SelectTypeUser
                style="z-index: 9; width: 14vw;"
                :selected="typeUserTitle"
                @update:selected="updateTypeUserTitle"
              />
            </div>
          </span>
        </div>
        <div class="form-group">
          <div class="header-group">
            <th class="space-labels">PAINEL</th>
            <th class="space-labels">PASTA</th>
            <th class="space-labels">OBJETO</th>
          </div>
          <div class="select-group">
            <th class="style-select">
              <SelectSearch
                name="painel"
                tabindex="0"
                @update="
                  limparPastaEObjeto();
                  atualizarSheet();
                  atualizarField();
                "
                @executeSearch="verifyReceivedThetextSearch"
                :options="paineis"
                :selected="painelTitle"
                @input="updatePainel"
                :removeSelecao="removeSelecao"
              >
              </SelectSearch>
            </th>
            <th class="style-select">
              <SelectSearch
                name="sheet"
                tabindex="1"
                @update="
                  limparObjeto();
                  atualizarObject();
                "
                @executeSearch="verifyReceivedThetextSearch"
                :options="sheets"
                :selected="sheetTitle"
                :spinner="spinner"
                @input="updatePasta"
                @without-input="resetPasta"
                :removeSelecao="removeSelecao"
              />
            </th>
            <th class="style-select">
              <SelectSearch
                name="object"
                tabindex="2"
                @executeSearch="verifyReceivedThetextSearch"
                :options="objetos"
                :selected="objetoTitle"
                :spinner="spinner"
                @input="updateObjeto"
                @without-input="resetObjeto"
                :removeSelecao="removeSelecao"
              />
            </th>
            <th style="height: 8%">
              <div class="style-adc">
                <button
                  style="border: transparent; background-color: transparent"
                  id="button-add"
                  v-if="!temItensSelecionados"
                  @click="adcionar"
                >
                  <p id="icon-add">+</p>
                </button>
              </div>
            </th>
          </div>
          <div class="lista-container">
            <tbody class="lista-containerr">
              <tr
                class="mutavel"
                v-for="(it, index) in filtro"
                :key="index"
                :ref="'item_' + index"
              >
                <td class="style-mutavel">{{ it.painel.title }}</td>
                <td class="style-mutavel">{{ it.sheet.title }}</td>
                <td class="style-mutavel">{{ it.objeto.title }}</td>
                <td>
                  <div class="style-adc">
                    <button
                      id="button-remove"
                      @click="remover_filtro(index, it)"
                    >
                      <i id="icon" class="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </div>
        </div>
        <div class="style-button">
          <span @click="salvar()" class="input_button">Salvar / Fechar</span>
        </div>
        <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
#button-remove {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 1.6vw;
}
.style-adc {
  display: flex;
  margin-right: 1vw;
  background-color: transparent;
  width: 2.7vw;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay .fa-gear {
  animation: spin 1s linear infinite;
}
.overlay {
  position: absolute;
  margin-left: -1%;
  top: 1;
  left: 1;
  width: 42%;
  height: 75%;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}
.style-obgr {
  color: red;
  padding: 2px;
  margin-left: 1px;
}
.style-mutavel {
  width: 100%;
  padding: 0vw 2vw;
  font-size: 1vw;
  display: flex;
  justify-content: space-between;
}
.btn_sair {
  cursor: pointer;
  color: red;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  width: 100%;
}
.space-labels {
  color: #2bb542;
  width: 31%;
  font-size: 1.1vw;
  font-weight: 300;
}
.style-padrao {
  font-size: 1.1vw;
  padding: 5px;
}
.space-style {
  display: flex;
  justify-content: space-between;
}
.popup {
  width: 44.5vw;
  height: 79vh;
  background: white;
  font-size: 1rem;
  border-radius: 10px;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  top: 6vw;
  left: 32vw;
  z-index: 1;
}
.name-group-style {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.style-select {
  /* width: 30%; */
  margin-left: 3%;
  height: 9.5%;
  font-weight: 300;
}
.style-type{
  font-weight: 300;
 
  z-index: 10;
}
.lista-container {
  display: block;
  width: 10;
  max-height: 35.8vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.lista-containerr {
  display: block;
  width: 10;
  max-height: 35.8vh;
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.input_padrao {
  border: 1px solid #b3b3b3;
  color: black;
  border-radius: 5px;
  flex: 2;
  min-width: 0;
}
.select-style {
  border: 1px solid #b3b3b3;
  color: black;
  border-radius: 5px;
  width: 40%;
  flex: 1;
}
.form-group {
  box-shadow: 1px 1px 5.5px #8b8b8b;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.header-group {
  display: flex;
  /* gap: 3.8vw; */
  /* margin-bottom: 10px; */
  padding: 10px;
}
.select-group {
  display: flex;
  /* height: 100%; */
  gap: 5px;
  border-bottom: 1px solid #929090;
}
.mutavel {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #929090;
}
.mutavel:hover {
  box-shadow: 1px 1px 5.5px #8b8b8b;
}
.mutavel p {
  display: flex;
}
.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.104);
  font-family: Arial, Helvetica, sans-serif;
}
.input_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bolder;
  height: 40px;
  border-radius: 2px;
  padding-left: 5px;
  text-align: center;
  color: white;
  width: 100%;
  background-color: #2bb542;
  border-radius: 5px;
}
.style-button {
  cursor: pointer;
  display: flex;
  justify-content: end;
  width: 30%;
  margin-left: 70%;
}
.style-adc {
  cursor: pointer;
  border: transparent;
  display: flex;
  justify-content: end;
}
#icon-excluir {
  border: none;
  background-color: white;
  color: red;
  border: transparent;
}
#icon-add {
  cursor: pointer;
  border: transparent;
  font-size: 1.8rem;
  border-radius: 10px;
  border: transparent;
  height: 95%;
  width: 1.8vw;
  background-color: #2bb542;
  color: white;
}
</style>
