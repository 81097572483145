<script>
//import this.$axios.get from 'this.$axios.get';
import Expediente from '../components/Expediente/Expediente.vue'
import ConfirmationModalVue from '../components/Comfirmacao/ConfirmationModal.vue'
const url = process.env.VUE_APP_API_URL
export default {
  components: {
    Expediente,
    ConfirmationModalVue
  },
  data() {
    return {
      showHoursPopUp: false,
      idEmpresa: null,
      filtrarexpediente: [],
      textForModal: 'Deseja realmente excluir este expediente?',
      selectedRows: [],
      estaOrdemAlfabetica: true,
      confirmarAoDeletar: false,
      dadosParaExcluir: [],
      marcarTodos: null,
      idParaEditarExpediente: null,
      loading: false,
    }
  },
  async created() {
    this.loading = true;

    const localStorageObject = JSON.parse(localStorage.getItem('User'))
    this.idEmpresa = localStorageObject.Id
    if(this.$route.query.popup){
      this.showHoursPopUp = true
    }
    if (this.idEmpresa) {
      this.$axios.get(url + '/expediente', {
        params: { id: this.idEmpresa }
      }).then((response) => {
        this.filtrarexpediente = response.data
        this.loading = false;
      }
    ).catch( ()=> {this.loading = false;})
  }
  },
  methods: {
    fecharPopUp() {
      this.showHoursPopUp = false
      this.idParaEditarExpediente = null
    },
    //excluir apenas 1 dado
    showDeleteModal(idExpediente) {
      this.dadosParaExcluir.push(idExpediente)
      this.confirmarAoDeletar = true
    },
    //excluir varios dados
    showDeletesModal() {
      this.textForModal = 'Deseja realmente excluir estes expedientes?'
      this.dadosParaExcluir = this.selectedRows
      this.confirmarAoDeletar = true
    },
    deletarExpedienteConfirmado() {
      this.confirmarAoDeletar = false
      this.$axios.delete(url + '/expediente', {data:{idExpediente:this.dadosParaExcluir, idEmpresa: this.idEmpresa}}).then((response) => {
        this.filtrarexpediente = response.data
        this.selectedRows = []
        if(this.marcarTodos){
          this.marcarTodos = null
        }
      }).catch((error) => {

      })
    },
    deleteUserCanceled() {
      this.confirmarAoDeletar = false
    },
    selecionartodos() {
      if(this.selectedRows.length === this.filtrarexpediente.length){
        this.selectedRows = []
      } else {
        this.selectedRows = this.filtrarexpediente.map((dados) => dados.id)
      }
    },
    editarExpediente(idExpediente){
      this.idParaEditarExpediente = idExpediente
      this.showHoursPopUp = true

    },
    ordernarOrdemAlfabetica() {
      if (!this.ordenacaoAscendente) {
          this.filtrarexpediente.sort((a, b) => a.nome.localeCompare(b.nome));
          this.ordenacaoAscendente = true;
      } else {
        this.filtrarexpediente.sort((a, b) => b.nome.localeCompare(a.nome));
        this.ordenacaoAscendente = false;
      }
    this.estaOrdemAlfabetica = !this.estaOrdemAlfabetica;
    },
  }
}
</script>
<template  >
  <main class="base">
    <header class="caixaTitulo">
      <Expediente v-if="showHoursPopUp" :idEmpresa="idEmpresa" :idExpediente="idParaEditarExpediente" @fecharPopUp="fecharPopUp"
        style="position: absolute; z-index: 10"></Expediente>
      <div class="caixaTitulo_titulo">Expedientes</div>
      <div>
        <button class="caixaTitulo_botao" @click="showHoursPopUp = true">
          <i class="fa-solid fa-plus"></i>
          Adicionar Expediente
          <div class="DicaTexto">Adicionar Expediente</div>
        </button>
      </div>
    </header>
    <ConfirmationModalVue class="modal-style" v-if="confirmarAoDeletar" :text="textForModal" @confirmed="deletarExpedienteConfirmado"
      @canceled="deleteUserCanceled" />
    <section class="conteudo">
      <div class="tabela">
        <div style="display:flex">
          <div class="icon-style">
            <i class="fa-solid fa-filter"></i>
          </div>
          <input style="margin-left: 17px;" type="text" placeholder="Pesquisar" class="pesquisa" />
          <button class="botaoPesquisa">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <table cellspacing="0">
          <thead>
            <!-- <tr>
            </tr> -->
            <tr class="nomedascolunas">
              <th style="text-align: center">
                <input type="checkbox" @click="selecionartodos" v-model="marcarTodos" />
              </th>
              <th>Editar</th>
              <th @click="ordernarOrdemAlfabetica">
                <span>Nome do expediente
                    <span v-if="estaOrdemAlfabetica">
                        <i class="fa-solid fa-arrow-up-wide-short"></i>
                    </span>
                    <span v-else>
                        <i class="fa-solid fa-arrow-down-wide-short"></i>
                    </span>
                </span>
            </th>
              <th style="width: 10%; align-items: center; ">
                <span style="width: 10%;
                 align-items: center;
                  text-align: center; 
                  margin-left: 2.9vh;">
                  Excluir
                </span>
                <button class="btn_rm" @click="showDeletesModal()" style="
                    font-size: 1rem;
                    color: #2bb542;
                    font-weight: bold;
                    text-align: center;
                    margin-left: -4.1%;
                  " v-if="selectedRows.length">
                  ({{ selectedRows.length }})
                  <i class="fa-solid fa-trash"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(row, index) in filtrarexpediente" :key="index">
            <tr class="back-color">
              <td class="quina">
                <input type="checkbox" style="width: 40px;" v-model="selectedRows" :value="row.id">
              </td>
              <td class="quina">
                <button style="width: 100%; cursor: pointer; border: none; background-color: transparent;" @click="editarExpediente(row.id)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
              <td>{{ row.nome }}</td>
              <td class="quina">
                <button style="width: 100%; color: red; font-size: 1.2rem; cursor: pointer; border: none; background-color: transparent;" @click="showDeleteModal(row.id)">
                  <i class="fa fa-xmark"></i>
                </button>
              </td>
            </tr>
            <tr class="line">
              <td colspan="8" class="naoSelecionavel">a</td>
            </tr>
          </tbody>
        </table>
        <div v-if="filtrarexpediente.length === 0" class="nadaEncontrado">
          <div>
            Nenhum expediente foi encontrado
            <i class="fa-solid fa-plug-circle-exclamation"></i>
          </div>
        </div>
        <div class="overlay" v-if="loading">
          <i id="icon-style"  class="fa-solid fa-gear"></i>
          <i id="icon-stylee"  class="fa-solid fa-gear"></i>
        </div>
      </div>
    </section>
  </main>
</template>
  
  
<style scoped>
.tabela {
  width: 100%;
  height: 70vh;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  overflow-x: scroll;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .overlay .fa-gear {
    animation: spin 1s linear infinite;
  }
  .modal-style{
    margin-left: 28rem;
  }
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#icon-style{
  align-items: center;
  font-size: 3rem;
  color: white;
}
#icon-stylee{
  font-size: 2rem;
  color: white;
  align-items: end;
}
h1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.caixaTitulo_botao:hover {
  background-color: #2bb542d7;
}

th,
td {
  padding: 5px;
  border-spacing: 35px;

}

.caixaTitulo_botao {
  position: relative;
  display: inline-block;
}

.caixaTitulo_botao:hover .DicaTexto {
  visibility: visible;
}

.icon-style {
  padding-left: 2.3vh;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.back-color:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

table {
  width: 100%;
}

thead {
  border-bottom: 1px solid #b3b3b3;
}

tr td {
  border-left: 1px solid #b3b3b3;
}

.nomedascolunas {
  text-align: left;
  color: #2bb542;
  font-family: initial;
  
}

thead {
  margin-bottom: 5px;
}

.nomedascolunas th {
  border-bottom: 1px solid #2bb542;
}

.pesquisa {
  width: 20%;
  height: 30px;
  padding-left: 5px;
  display: flex;
}

.line {
  padding: 0;
  color: transparent;
  border-left: none;
  border-right: none;
}

.line td {
  border-bottom: 1px solid #b3b3b3;
  border-left: none;
  height: 1px;
  padding: 0;
}

.line_vazia td {
  height: 1px;
  border: none;
}

.quina {
  width: 15px;
  text-align: center;
}


.botaoPesquisa {
  background-color: white;
  padding: 6px;
  border: 0px;
  cursor: pointer;
}

.nadaEncontrado {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  color: #c1c1c1;
}

.btn_rm {
  font-size: 1rem;
  color: #2bb542;
  font-weight: bold;
  text-align: center;
  margin-left: 1.7vw;
}


.naoSelecionavel {
  font-size: 2px;
  color: transparent;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}
</style>
  