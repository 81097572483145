<script>
// import this.$axios.get from "axios";
import { AlertDataSend } from "../../components/alert";

export default {
  name: "numerosBloqueados",
  data() {
    return {
      numerosBloqueados: [],
      colunaOrdenada: "",
      ordemAscendente: true,
      selectedRows: [],
      dados: [],
      todos_itens: false,
      estaOrdemAlfabetica: true,
      mostrarBotaoDesbloqueio: false,
      loading: false,
      estaOrdemEmpresa: true,
    };
  },

  created() {
    this.loading = true;
    const url = process.env.VUE_APP_API_URL;
    const localStorageObject = JSON.parse(localStorage.getItem("User"));

    this.idEmpresa = localStorageObject.Id;
    this.User = localStorageObject.User;

    if (this.idEmpresa && this.User === "Data Priority") {
      this.$axios
        .get(`${url}/numerobloqueado`)
        .then((response) => {
          this.numerosBloqueados = response.data;
        })
        .catch((error) => {
          console.error("Erro ao buscar números bloqueados:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.$axios
        .get(`${url}/numerobloqueado/bycompany`, {
          params: {
            idCompany: this.idEmpresa,
          },
        })
        .then((response) => {
          this.numerosBloqueados = response.data;
        })
        .catch((error) => {
          console.error("Erro ao buscar números bloqueados:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    desbloqueiarNumero(id) {
      const url = process.env.VUE_APP_API_URL;

      this.$axios
        .delete(`${url}/numerobloqueado`, { data: { id: id } })
        .then(() => {
          this.numerosBloqueados = this.numerosBloqueados.filter(
            (it) => it.id !== id
          );
          AlertDataSend("Número desbloqueado com sucesso", "alert");
        })
        .catch((error) => {
          console.error("Erro ao desbloquear número:", error);
        });
    },
    ordernarOrdemAlfabetica() {
      const ordenado = this.numerosBloqueados.slice();
      if (this.estaOrdemAlfabetica) {
        ordenado.sort((a, b) =>
          a.nome.toLowerCase().localeCompare(b.nome.toLowerCase())
        );
        this.estaOrdemAlfabetica = false;
      } else {
        ordenado.sort((a, b) =>
          b.nome.toLowerCase().localeCompare(a.nome.toLowerCase())
        );
        this.estaOrdemAlfabetica = true;
      }

      this.numerosBloqueados = ordenado;
    },

    SortByCompanyName() {
      const ordenado = this.numerosBloqueados.slice();
      if (this.estaOrdemEmpresa) {
        ordenado.sort((a, b) =>
          a.nome_empresa
            .toLowerCase()
            .localeCompare(b.nome_empresa.toLowerCase())
        );
        this.estaOrdemEmpresa = false;
      } else {
        ordenado.sort((a, b) =>
          b.nome_empresa
            .toLowerCase()
            .localeCompare(a.nome_empresa.toLowerCase())
        );
        this.estaOrdemEmpresa = true;
      }

      this.numerosBloqueados = ordenado;
    },
    selecionarCheckbox() {
      this.todos_itens =
        this.selectedRows.length === this.numerosBloqueados.length;
      this.mostrarBotaoDesbloqueio = this.selectedRows.length > 0;
    },
    marcarTodosCheckbox() {
      if (this.todos_itens) {
        this.selectedRows = this.numerosBloqueados.map((row, index) => row.id);
      } else {
        this.selectedRows = [];
      }
      this.mostrarBotaoDesbloqueio = this.todos_itens;
    },
    desbloqueiarSelecionados() {
      if (this.selectedRows.length === 0) {
        AlertDataSend("Nenhum selecionado.");
        return;
      }
      const url = process.env.VUE_APP_API_URL;
      const desbloqueioPromises = [];
      for (const id of this.selectedRows) {
        const desbloqueioPromise = this.$axios
          .delete(`${url}/numerobloqueado`, { data: { id: id } })
          .then(() => {
            this.numerosBloqueados = this.numerosBloqueados.filter(
              (it) => it.id !== id
            );
          })
          .catch((error) => {
            console.error("Erro ao desbloquear número:", error);
          });

        desbloqueioPromises.push(desbloqueioPromise);
      }
      Promise.all(desbloqueioPromises).then(() => {
        AlertDataSend("Números desbloqueados com sucesso.", "alert");
        this.mostrarBotaoDesbloqueio = false;
        this.todos_itens = false;
      });
    },
  },
};
</script>

<template>
  <main class="base">
    <header>
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">Números Bloqueados</div>
      </div>
    </header>
    <section class="conteudo">
      <div class="tabela">
        <table cellspacing="0">
          <thead>
            <tr class="nomedascolunas">
              <th style="text-align: center">
                <input
                  type="checkbox"
                  v-model="todos_itens"
                  @change="marcarTodosCheckbox"
                  style="font-size: 1.4rem"
                />
              </th>
              <th @click="SortByCompanyName()">
                <span
                  >Nome da empresa
                  <span v-if="estaOrdemEmpresa">
                    <i class="fa-solid fa-arrow-up-wide-short"></i
                  ></span>
                  <span v-else
                    ><i class="fa-solid fa-arrow-down-wide-short"></i></span
                ></span>
              </th>
              <th @click="ordernarOrdemAlfabetica()">
                <span
                  >Nome do usuário
                  <span v-if="estaOrdemAlfabetica">
                    <i class="fa-solid fa-arrow-up-wide-short"></i
                  ></span>
                  <span v-else
                    ><i class="fa-solid fa-arrow-down-wide-short"></i></span
                ></span>
              </th>
              <th>Número</th>
              <th style="width: 10%">
                Desbloquear
                <button
                  v-if="mostrarBotaoDesbloqueio"
                  @click="desbloqueiarSelecionados"
                  class="btn_desbloqueio"
                  v-on:mouseover="mostrarLegenda"
                >
                  <i class="fa-solid fa-unlock"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(row, index) in numerosBloqueados" :key="index">
            <tr class="back-color">
              <td
                style="text-align: center; border-left: none; border-top: none"
              >
                <input
                  type="checkbox"
                  v-model="selectedRows"
                  :value="row.id"
                  @change="selecionarCheckbox"
                />
              </td>
              <td>{{ row.nome_empresa }}</td>
              <td>{{ row.nome }}</td>
              <td>{{ row.numero_telefone }}</td>
              <td class="quina">
                <button
                  class="btn_edit"
                  style="color:red; font-size: 1rem; border-left: none"
                  @click="desbloqueiarNumero(row.id)"
                >
                  <i class="fa-solid fa-unlock"></i>
                </button>
              </td>
            </tr>
            <tr class="line">
              <td colspan="8" class="naoSelecionavel">a</td>
            </tr>
          </tbody>
        </table>
        <div v-if="numerosBloqueados.length === 0" class="encontradoNada">
          <div>
            Nada foi encontrado
            <i class="fa-solid fa-plug-circle-exclamation"></i>
          </div>
        </div>
        <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.tabela {
  width: 100%;
  height: 70vh;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  overflow-x: scroll;
  position: relative;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 0;
}

.nomedascolunas {
  text-align: left;
  color: #2bb542;
  font-family: initial;
}

.nomedascolunas th {
  border-bottom: 1px solid #2bb542;
}

th,
td {
  padding: 5px;
  border-spacing: 35px;
}

table {
  width: 100%;
}

tr td {
  border-left: 1px solid #b3b3b3;
}

.back-color:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

.pesquisa {
  width: 80%;
  height: 30px;
  padding-left: 5px;
  display: flex;
}

.line {
  padding: 0;
  color: transparent;
  border-left: none;
  border-right: none;
}

.line td {
  border-bottom: 1px solid #b3b3b3;
  border-left: none;
  height: 1px;
  padding: 0;
}

.line_vazia td {
  height: 1px;
  border: none;
}

.quina {
  width: 15px;
  text-align: center;
}

.naoSelecionavel {
  font-size: 2px;
  color: transparent;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn_desbloqueio {
  border: none;
  color: green;
  cursor: pointer;
  margin-left: -105%;
  background-color: transparent;
}

.encontradoNada {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  color: #c1c1c1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay .fa-gear {
  animation: spin 1s linear infinite;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#icon-style {
  align-items: center;
  font-size: 3rem;
  color: white;
}

#icon-stylee {
  font-size: 2rem;
  color: white;
  align-items: end;
}
</style>





